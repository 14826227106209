import {
  BrandViewHeader,
  DropdownRadioMenu,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { ScreeningStatusMappings } from 'components/ScreeningStatusPill';
import { useHistory } from 'react-router-dom';
import { UrlParam, Color } from 'design-system/data';
import { useStatefulQueryParams } from 'design-system/utils';
import { FormInput } from 'views/FDARegistration/shared/components/Form';
import { useMemo } from 'react';
import styles from './header-styles.module.scss';
import { Pillar } from '../data/types';

interface HeaderProps {
  policyName: string;
  skuName: string;
  status: string;
  pillars: Pillar[];
  caption?: any;
  breadcrumbItems?: { text: string; link: string }[];
}

const SORT_OPTIONS = {
  'default': 'Default',
  'requirement_name__asc': 'Requirement (A to Z)',
  'requirement_name__desc': 'Requirement (Z to A)',
};

const RetailerBrandEligibilityHeader = ({
  policyName,
  skuName,
  status,
  pillars,
  caption,
  breadcrumbItems,
}: HeaderProps) => {
  const policyStatus = ScreeningStatusMappings[status];

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  const [queryParams, setQueryParams] = useStatefulQueryParams({
    [UrlParam.Direction]: params.get(UrlParam.Direction) || '',
    [UrlParam.Sort]: params.get(UrlParam.Sort) || '',
    [UrlParam.Term]: params.get(UrlParam.Term) || '',
    [UrlParam.PillarIds]: params.get(UrlParam.PillarIds) || '',
  });

  const search = queryParams[UrlParam.Term];
  const setSearch = (value: string) => {
    setQueryParams({ [UrlParam.Term]: value });
  };

  const filterPillars = queryParams[UrlParam.PillarIds];
  const setFilterPillars = (value: string) => {
    setQueryParams({ [UrlParam.PillarIds]: value });
  };

  const activeSort =
    queryParams[UrlParam.Sort] && queryParams[UrlParam.Direction]
      ? `${queryParams[UrlParam.Sort]}__${queryParams[UrlParam.Direction]}`
      : 'default';
  const setActiveSort = (value: string) => {
    const [criteria, direction] = value.split('__');
    setQueryParams({
      [UrlParam.Sort]: criteria,
      [UrlParam.Direction]: direction,
    });
  };

  const pillarOptions = useMemo((): { [key: string]: string } => {
    const baseOptions: { [key: string]: string } = { 'all': 'All' };

    if (!pillars || pillars.length === 0) {
      return baseOptions;
    }

    const mappedPillars = pillars.reduce((acc, pillar) => {
      acc[pillar.id.toString()] = pillar.name;
      return acc;
    }, {} as { [key: string]: string });

    return { ...baseOptions, ...mappedPillars };
  }, [pillars]);

  return (
    <>
      <BrandViewHeader
        title={skuName || 'SKU'}
        subTitle={`Status of all requirements for this SKU${
          policyName ? ` for ${policyName}.` : '.'
        }`}
        showExportButton={false}
        showFilterButton={false}
        breadcrumbsItems={breadcrumbItems}
        headerCtaText={policyStatus?.label}
        headerCtaColor={policyStatus?.color}
      />
      {caption && caption()}
      <div className={styles['options-row']}>
        <FormInput
          type="text"
          // @ts-ignore
          value={search}
          // @ts-ignore
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          // @ts-ignore
          icon={
            <PhosphorIcon
              iconName="MagnifyingGlass"
              size="1.5rem"
              weight={PhosphorIconWeight.Light}
              color={Color.Neutral600.value}
            />
          }
        />
        <div className={styles['filters-wrapper']}>
          <DropdownRadioMenu
            fromLeft={true}
            options={pillarOptions}
            reverse
            activeValue={filterPillars}
            setActiveValue={setFilterPillars}
            triggerElement={
              <div className={styles['trigger-container']}>
                <PhosphorIcon iconName="FunnelSimple" size={20} />
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.P}
                  color={Color.Neutral600}
                  className={styles.nowrap}
                >
                  Filter
                </Text>
              </div>
            }
          />
          <DropdownRadioMenu
            fromLeft={true}
            options={SORT_OPTIONS}
            reverse
            activeValue={activeSort}
            setActiveValue={setActiveSort}
            triggerElement={
              <div className={styles['trigger-container']}>
                <PhosphorIcon iconName="ArrowsDownUp" size={20} />
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.P}
                  color={Color.Neutral600}
                  className={styles.nowrap}
                >
                  {activeSort
                    ? `Sort by: ${
                        SORT_OPTIONS[activeSort as keyof typeof SORT_OPTIONS]
                      }`
                    : 'Sort by'}
                </Text>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default RetailerBrandEligibilityHeader;
