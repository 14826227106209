import { Button, ButtonKind, LinkKind } from 'design-system/components';
import styles from '../retailerBrandDashboard.module.scss';
import NextStepsCTA from '../NextStepsCTA/NextStepsCTA';
import {
  hydrateSKUsFlow,
  hydratePolicySubmissionFlow,
  SKUPolicyStateType,
} from './constants';
import PolicySubmissionFlowCard from './PolicySubmissionFlowCard';

interface SubmissionCardProps {
  retailerName: string;
  policyName: string;
  submissionFlow: string;
  submissionStatus: string;
  loading: boolean;
  percentComplete: number;
  canSubmit: boolean;
  supportEmail: string;
  setModalOpen: (value: boolean) => void;
  someSkusQualify: boolean;
}

const SubmissionCard = ({
  retailerName,
  policyName: name,
  submissionFlow,
  submissionStatus,
  loading,
  percentComplete,
  canSubmit,
  supportEmail,
  setModalOpen,
  someSkusQualify,
}: SubmissionCardProps) => {
  const submitButton = (
    <Button
      kind={ButtonKind.Primary}
      onClick={() => setModalOpen(true)}
      className={styles.button}
      usePhosphorIcon
      iconSize={20}
      iconPosition="right"
      iconName="ArrowRight"
      iconWeight="bold"
      disabled={!canSubmit}
    >
      Submit
    </Button>
  );

  const OneOrMoreSkusCard = () => {
    const policyStates = hydrateSKUsFlow(
      submissionStatus,
      name,
      retailerName,
      supportEmail,
      submitButton,
      someSkusQualify
    ) as SKUPolicyStateType[];
    return policyStates.map((policyState) => (
      <NextStepsCTA
        cardHeading={policyState.title}
        phosphorIconName={policyState.phosphorIconName || ''}
        descriptiveText={policyState.subtitle || ''}
        ctaButtonText={policyState.link?.text}
        redirectPath={policyState.link?.to}
        linkKind={policyState.link?.linkKind || LinkKind.Internal}
        buttonAction={policyState.submitButton}
      />
    ));
  };

  const CompletePolicyCard = () => {
    const policyState = hydratePolicySubmissionFlow(
      submissionStatus,
      name,
      retailerName
    );
    return (
      <PolicySubmissionFlowCard
        policyState={policyState}
        submitButton={submitButton}
        loading={loading}
        percentComplete={percentComplete}
        canSubmit={canSubmit}
        supportEmail={supportEmail}
        submissionStatus={submissionStatus}
      />
    );
  };

  // Only evaluate submission flow in one place.
  if (!submissionFlow || submissionFlow === 'complete') {
    return <CompletePolicyCard />;
  }
  if (submissionFlow === 'one_or_more_skus') {
    return <OneOrMoreSkusCard />;
  }
  return null;
};

export default SubmissionCard;
