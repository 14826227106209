import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  CloudinaryImg,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';

import Disclaimer from 'views/Brands/shared/Disclaimer';
import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import Header from './components/Header';
import Table from './components/Table';
import styles from './sku-level-status.module.scss';
import { useSKUEligibilityContext } from './context/SKUEligibilityContext';

interface SKULevelStatusPageProps {
  retailerBrand?: any;
  isRetailer?: boolean;
}

const SKULevelStatusPageView = ({
  retailerBrand,
  isRetailer = false,
}: SKULevelStatusPageProps) => {
  const { brand_id, policy_id } = useParams();
  const { useApi, user } = useApp();
  const response = useSKUEligibilityContext();
  const retailerId = isRetailer ? user?.organization?.in_orgable_id : null;
  const policyRes = useApi(
    `/api/v4/retailers/${retailerId}/consumer_brands/${brand_id}/policies`,
    {
      enabled: Boolean(isRetailer && retailerId && brand_id),
    }
  );

  const currentPolicy = useMemo(() => {
    const policies = policyRes?.data?.policies;
    return policies?.find(
      (policy: any) => parseInt(policy.id) === parseInt(policy_id)
    );
  }, [policyRes?.data?.policies, policy_id]);

  const caption = () => {
    return isRetailer ? (
      <div className={styles.caption}>
        {currentPolicy?.image_path ? (
          <>
            <CloudinaryImg
              fallbackElement={
                <PhosphorIcon size={40} iconName="ShieldCheck" />
              }
              src={currentPolicy.image_path}
            />{' '}
          </>
        ) : null}
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.Span}>
          {response?.data?.retailerPolicyName
            ? response?.data?.retailerPolicyName
            : 'Policy'}{' '}
          Status
        </Text>
      </div>
    ) : null;
  };

  const breadcrumbItems = isRetailer
    ? [
        {
          text: 'Submissions',
          link: PATHS.retailerBrandsSubmissions,
        },
        {
          text: response?.data?.consumerBrandName,
          link: '',
        },
      ]
    : [
        {
          text: 'Eligibility',
          link: PATHS.retailerBrandEligibility,
        },
        {
          text: response?.data?.retailerPolicyName,
          link: '',
        },
      ];

  return (
    <main>
      <Header
        policyName={response?.data?.retailerPolicyName}
        breadcrumbItems={breadcrumbItems}
        brandName={response?.data?.consumerBrandName}
        isRetailer={isRetailer}
        caption={caption}
      />
      <Table
        data={response}
        uniquePillarResultNames={
          isRetailer === false
            ? response?.data?.pillars?.map((pillar) => pillar.name)
            : undefined
        }
        isRetailer={isRetailer}
      />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand?.retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default SKULevelStatusPageView;
