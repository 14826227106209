import {
  BrandViewHeader,
  PhosphorIconWeight,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  DropdownRadioMenu,
  FilterDropdown,
} from 'design-system/components';
import { useHistory } from 'react-router-dom';
import { UrlParam, Color } from 'design-system/data';
import { FormInput } from 'views/FDARegistration/shared/components/Form';
import { useStatefulQueryParams } from 'design-system/utils';

import styles from './header-styles.module.scss';
import { useSKUEligibilityContext } from '../context/SKUEligibilityContext';

interface HeaderProps {
  policyName: string;
  breadcrumbItems?: { text: string; link: string }[];
  brandName?: string;
  isRetailer?: boolean;
  caption?: any;
}

const SORT_OPTIONS = {
  'product_name__asc': 'SKU name (A to Z)',
  'product_name__desc': 'SKU name (Z to A)',
  'sku_id__asc': 'SKU ID (ascending)',
  'sku_id__desc': 'SKU ID (descending)',
};

const FILTER_DROPDOWN_OPTIONS = [
  { value: 'pending', label: 'Pending' },
  { value: 'pass', label: 'Meets' },
  { value: 'fail', label: 'Does not meet' },
  { value: 'needs_information', label: 'Needs attention' },
  { value: 'not_applicable', label: 'Not applicable' },
];

const RetailerBrandEligibilityHeader = ({
  policyName,
  breadcrumbItems,
  brandName,
  isRetailer,
  caption,
}: HeaderProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const { selectedValues, setSelectedValues, data } =
    useSKUEligibilityContext();

  const [queryParams, setQueryParams] = useStatefulQueryParams({
    [UrlParam.Direction]: params.get(UrlParam.Direction) || '',
    [UrlParam.Sort]: params.get(UrlParam.Sort) || '',
    [UrlParam.ProductName]: params.get(UrlParam.ProductName) || '',
  });

  const search = queryParams[UrlParam.ProductName];
  const setSearch = (value: string) => {
    setQueryParams({ [UrlParam.ProductName]: value });
  };

  const activeSort =
    queryParams[UrlParam.Sort] && queryParams[UrlParam.Direction]
      ? `${queryParams[UrlParam.Sort]}__${queryParams[UrlParam.Direction]}`
      : 'product_name__asc';
  const setActiveSort = (value: string) => {
    const [criteria, direction] = value.split('__');
    setQueryParams({
      [UrlParam.Sort]: criteria,
      [UrlParam.Direction]: direction,
    });
  };

  const title = isRetailer && brandName ? brandName : policyName;
  const subtitle =
    isRetailer && brandName
      ? `
    View the SKUs for the ${brandName} submission to the ${policyName} program here.
  `
      : `View the eligibility status of your SKUs ${
          policyName ? `for ${policyName} ` : ''
        }here.`;

  return (
    <>
      <BrandViewHeader
        title={title}
        subTitle={subtitle}
        showExportButton={false}
        showFilterButton={false}
        breadcrumbsItems={breadcrumbItems}
      />
      {caption && caption()}
      <div className={styles['options-row']}>
        <FormInput
          type="text"
          // @ts-ignore
          value={search}
          // @ts-ignore
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          // @ts-ignore
          icon={
            <PhosphorIcon
              iconName="MagnifyingGlass"
              size="1.5rem"
              weight={PhosphorIconWeight.Light}
              color={Color.Neutral600.value}
            />
          }
        />
        <div className={styles['filters-wrapper']}>
          <FilterDropdown
            accordionOptions={[
              {
                title: policyName,
                options: FILTER_DROPDOWN_OPTIONS,
              },
              ...((isRetailer === false ? data?.pillars : undefined) || []).map(
                (pillar) => ({
                  title: pillar.name,
                  options: FILTER_DROPDOWN_OPTIONS.map((option) => ({
                    ...option,
                    value: `${pillar.id}:${option.value}`,
                  })),
                })
              ),
            ]}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
          <DropdownRadioMenu
            fromLeft={true}
            options={SORT_OPTIONS}
            reverse
            activeValue={activeSort}
            setActiveValue={setActiveSort}
            triggerElement={
              <div className={styles['trigger-container']}>
                <PhosphorIcon iconName="ArrowsDownUp" size={20} />
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.P}
                  color={Color.Neutral600}
                  className={styles.nowrap}
                >
                  {activeSort
                    ? `Sort by: ${
                        SORT_OPTIONS[activeSort as keyof typeof SORT_OPTIONS]
                      }`
                    : 'Sort by'}
                </Text>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default RetailerBrandEligibilityHeader;
