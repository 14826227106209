// @ts-nocheck
import {
  useCallback,
  useContext,
  useEffect,
  createContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';

import {
  Button,
  ButtonKind,
  ToastKind,
  ContextMenu,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { PolicyRequirementType, RequestMethod } from 'design-system/data';
import {
  handleKeyboardEvent,
  convertSnakeToCamelCase,
  KeyboardCode,
} from 'design-system/utils';

import { ROLES } from 'constants/index';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';
import { useApp } from 'context/AppContext';
import useApi from 'services/api/useApi';
import { AttestationActionType } from 'views/Brands/RetailerBrandRequirementSummary/constants';
import styles from './cta-modals.module.scss';

const RequirementActions = createContext(null);

export const RequirementActionsProvider = ({ children }) => {
  const { user } = useApp();
  const history = useHistory();
  const location = useLocation();
  const { policy_id, requirement_id } = useParams();
  const isRetailerUser = user?.hasRole(ROLES.retailer);

  const [attestationActions, setAttestationActions] = useState({});
  const [currentProductId, setCurrentProductId] = useState(null);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [validationCount, setValidationCount] = useState(0);
  const [triggerValidation, setTriggerValidation] = useState(false);
  const [conditionsValidStatuses, setConditionsValidStatuses] = useState({});
  const [validationComplete, setValidationComplete] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastData, setToastData] = useState({});
  const [initEdit, setInitEdit] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  // Reset attestation actions when modal is closed
  useEffect(() => {
    if (!modalOpen) {
      setAttestationActions({});
    }
  }, [modalOpen]);

  // Reset state on any modal id change. Note: Attestation resets happen before this to avoid collision with setting initial values.
  useEffect(() => {
    setInitEdit(false);
    setHasChanged(false);
    setConfirmClose(false);
    setHasErrors(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId, selectedRequirement?.id]);

  const isBrandRequirement =
    selectedRequirement?.requirementType === PolicyRequirementType.Brand;
  const currentRequirementId =
    selectedRequirement?.id || (isRetailerUser ? requirement_id : policy_id);

  const handleSuccess = (message) => {
    setToastData(() => {
      return {
        kind: ToastKind.Success,
        title: 'Action Successful',
        message,
      };
    });
    setInitEdit(true);
    setToastOpen(true);
    setHasChanged(false);
    setConfirmClose(false);
  };

  // Helper function to handle error messages
  const handleError = (message) => {
    setToastData(() => {
      return { kind: ToastKind.Error, title: 'Error', message };
    });
    setToastOpen(true);
  };

  const onSuccess = (toastMessage) => {
    handleSuccess(toastMessage);
  };

  const { call: updateIngredientFunction } = useAsyncCall(
    async (formulationId, ingredientId, functionId) => {
      const url = `/api/product_design/v1/formulations/${formulationId}/chemical_ingredients/${ingredientId}`;
      await fetcher(url, {
        method: 'put',
        body: { ingredient_function_id: functionId },
      });
      onSuccess('Ingredient function successfully updated!');
    }
  );

  const { call: addIngredient } = useAsyncCall(
    async (
      productDesignFormulationId,
      chemicalCompoundId,
      rawIngredientName
    ) => {
      const url = `/api/product_design/v1/formulations/${productDesignFormulationId}/chemical_ingredients`;
      await fetcher(url, {
        method: 'POST',
        body: {
          'chemical_compound_id': chemicalCompoundId,
          'raw_ingredient_name': rawIngredientName,
        },
      });
      onSuccess('Ingredient successfully added!');
    }
  );

  const { call: updatePercentage } = useAsyncCall(
    async (formulationId, ingredientId, percentage) => {
      const url = `/api/product_design/v1/formulations/${formulationId}/chemical_ingredients/${ingredientId}`;
      await fetcher(url, { method: 'put', body: { percentage } });
      onSuccess('Percentage successfully updated!');
    }
  );
  const { call: resolveIngredientName } = useAsyncCall(
    async (formulationId, ingredientId, newIngredientId) => {
      const url = `/api/product_design/v1/formulations/${formulationId}/chemical_ingredients/${ingredientId}`;
      await fetcher(url, {
        method: 'PATCH',
        body: { chemical_compound_id: Number(newIngredientId) },
      });
      onSuccess('Name successfully resolved!');
    }
  );

  // Handle attestation submission
  const handleAttestation = useAsyncCall(
    async (allConditionsActions, allConditionsFile, allComposableValues) => {
      let results = [];
      if (allConditionsActions?.length) {
        const result = await handleActionAttestation(allConditionsActions);
        results.push(result);
      }

      if (allConditionsFile?.length) {
        const result = await handleFileAttestation(allConditionsFile);
        results.push(result);
      }

      if (allComposableValues?.length) {
        const result = await handleComposableAttestation(allComposableValues);
        results.push(result);
      }

      // if all responses are successful, show success message
      if (results.every((result) => result)) {
        onSuccess(
          'Attestation submitted successfully, and is now processing. Please continue the flow with the next requirement if applicable, and allow a few minutes to process your current answer. The status will be updated when results are ready.'
        );
      }
    }
  );
  const actionSubmitLoading = handleAttestation.loading;

  const handleActionAttestation = async (allConditionsActions) => {
    const url = `/api/v4/requirements/${currentRequirementId}/actions/respond?${
      currentProductId ? `consumer_product_id=${currentProductId}` : ''
    }`;
    try {
      const response = await fetcher(url, {
        method: RequestMethod.PUT,
        enabled: !!currentRequirementId,
        body: { attestation_proofs: allConditionsActions.flat() },
      });
      const updatedRequirementId = response.updated_requirement_id;
      if (updatedRequirementId) {
        history.push(
          location.pathname.replace(currentRequirementId, updatedRequirementId)
        );
      }
    } catch (error) {
      handleError('Apologies. Failed to submit attestation.');
      console.error(error);
      return false;
    }
    return true;
  };

  const handleFileAttestation = async (allConditionsFiles) => {
    for (const conditionFiles of allConditionsFiles.flat()) {
      const { conditionId, files, policyRequirementId } = conditionFiles;
      const params = new URLSearchParams({
        'policy_requirement_id': policyRequirementId,
      });
      if (currentProductId) {
        params.append('consumer_product_id', currentProductId);
      }
      if (files?.length) {
        for (const document of files) {
          if (document instanceof File) {
            const formData = new FormData();
            formData.append('document', document);
            try {
              await fetcher(
                `/api/v4/requirements/${currentRequirementId}/actions/${conditionId}/upload_document?${params.toString()}`,
                { method: RequestMethod.POST, body: formData }
              );
            } catch (error) {
              handleError(
                'Something’s not quite right. Failed to submit attestation.'
              );
              console.error(error);
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const handleComposableAttestation = async (allConditionsComposable) => {
    for (const conditionComposable of allConditionsComposable.flat()) {
      const { conditionId, response_value } = conditionComposable;

      const url = `/api/v4/requirements/${currentRequirementId}/actions/${conditionId}/composable_inputs`;
      try {
        await fetcher(url, {
          method: RequestMethod.PUT,
          body: { inputs: response_value },
        });
      } catch (error) {
        handleError('Apologies. Failed to submit attestation.');
        console.error(error);
        return false;
      }
    }
    return true;
  };

  // Handle retailer response submission
  const submitRetailerResponse = useAsyncCall(
    async (conditionId, proofId, isApproved, notes) => {
      const url = `/api/v4/requirements/${currentRequirementId}/conditions/${conditionId}/attestation_proofs/${proofId}/respond`;
      await fetcher(url, {
        method: RequestMethod.POST,
        body: {
          consumer_product_id: currentProductId,
          status: isApproved ? 'approved' : 'rejected',
          retailer_notes: notes,
        },
      });
      onSuccess('Review of attestation submitted successfully');
    }
  );

  // Determine if actions API should be enabled
  const isActionsAPIEnabled = useMemo(() => {
    if (!currentRequirementId) return false;
    if ((isRetailerUser && requirement_id) || policy_id)
      return !!currentProductId;
    return isBrandRequirement;
  }, [
    isRetailerUser,
    policy_id,
    currentProductId,
    isBrandRequirement,
    requirement_id,
    currentRequirementId,
  ]);

  // Fetch conditions data from API
  const {
    data,
    loading: conditionsLoading,
    error: conditionsError,
  } = useApi(`/api/v4/requirements/${currentRequirementId}/actions`, {
    param: {
      ...(currentProductId ? { consumer_product_id: currentProductId } : {}),
    },
    enabled: isActionsAPIEnabled,
  });

  // Convert conditions data and determine attestation permissions
  const conditions = useMemo(
    () => convertSnakeToCamelCase(data?.actions?.filter(Boolean)) || [],
    [data?.actions]
  );

  const canAttest = data?.permissions?.can_attest ?? true;

  // Initialize conditions valid statuses
  const initialConditionsValidStatuses = useMemo(
    () =>
      !conditionsLoading && conditions?.length
        ? conditions.reduce((acc, current) => {
            // Skip if conditionId is not present - eg resolve name conditions
            if (current.conditionId) {
              acc[current.conditionId] = false;
            }
            return acc;
          }, {})
        : {},
    [conditions, conditionsLoading]
  );

  const conditionCount = conditions?.length || 0;

  // Update conditions valid statuses when initial conditions change
  useEffect(() => {
    setConditionsValidStatuses(initialConditionsValidStatuses);
  }, [initialConditionsValidStatuses]);

  // Handle condition validation
  const handleConditionValidation = useCallback((conditionId, isValid) => {
    setConditionsValidStatuses((prev) => ({ ...prev, [conditionId]: isValid }));
    setValidationCount((prevCount) => prevCount + 1);
  }, []);

  // Check if validation is complete and proceed to submission
  useEffect(() => {
    if (modalOpen && triggerValidation && validationCount === conditionCount) {
      setValidationComplete(true);
    }
  }, [validationCount, conditionCount, modalOpen, triggerValidation]);

  // Prepare and send attestation submission
  const sendSubmit = useCallback(() => {
    let responseActionsArray = [];
    let responseFilesArray = [];
    let responseComposableArray = [];
    Object.entries(attestationActions).forEach(([actionType, actions]) => {
      switch (actionType) {
        case AttestationActionType.Documentation: {
          const formattedFiles = actions.map((action) => ({
            policyRequirementId: action.policyRequirementId,
            conditionId: action.conditionId,
            files: action.responseValue.value,
          }));
          if (formattedFiles.length) {
            responseFilesArray.push(formattedFiles);
          }
          break;
        }
        case AttestationActionType.Composable: {
          const formattedComposableActions = actions.map((action) => ({
            policyRequirementId: action.policyRequirementId,
            conditionId: action.conditionId,
            response_value: action.responseValue.value,
          }));
          if (formattedComposableActions.length) {
            responseComposableArray.push(formattedComposableActions);
          }
          break;
        }
        default: {
          const formattedActions = actions.map((action) => ({
            policy_requirement_id: action.policyRequirementId,
            condition_id: action.conditionId,
            response_value: action.responseValue.value,
            note_value: action.responseValue.noteValue || null,
            action_type: action.responseValue.conditionType,
          }));
          if (formattedActions.length) {
            responseActionsArray.push(formattedActions);
          }
          break;
        }
      }
    });
    return handleAttestation.call(
      responseActionsArray,
      responseFilesArray,
      responseComposableArray
    );
  }, [attestationActions, handleAttestation]);

  // Check validation completion and handle submission
  useEffect(() => {
    if (validationComplete && modalOpen) {
      setValidationComplete(false);
      setTriggerValidation(false);
      setValidationCount(0);

      const allValid = Object.values(conditionsValidStatuses).every(
        (status) => status
      );
      if (allValid) {
        setHasErrors(false);
        sendSubmit();
      } else {
        setHasErrors(true);
      }
    }
  }, [
    validationComplete,
    modalOpen,
    conditionsValidStatuses,
    handleAttestation,
    conditionCount,
    sendSubmit,
  ]);

  // Trigger validation process
  const handleSubmit = useCallback(() => {
    setTriggerValidation(true);
    setValidationComplete(false);
    setValidationCount(0);
  }, []);

  // Handle attestation action changes
  const handleChange = useCallback(
    (conditionId, actionType, responseValue, policyRequirementId) => {
      setAttestationActions((prevActions) => {
        const actions = { ...prevActions };
        if (!actions[actionType]) {
          actions[actionType] = [];
        }
        const existingActionIndex = actions[actionType].findIndex(
          (action) => action.conditionId === conditionId
        );
        if (existingActionIndex !== -1) {
          actions[actionType][existingActionIndex] = {
            conditionId,
            responseValue,
            policyRequirementId,
          };
        } else {
          actions[actionType].push({
            conditionId,
            responseValue,
            policyRequirementId,
          });
        }
        return actions;
      });
    },
    []
  );

  // Memoize context value to avoid unnecessary re-renders
  const context = useMemo(
    () => ({
      handleAttestation,
      updateIngredientFunction,
      addIngredient,
      updatePercentage,
      resolveIngredientName,
      conditions,
      canAttest,
      conditionsLoading,
      conditionsError,
      toastData,
      handleChange,
      triggerValidation,
      handleConditionValidation,
      modalOpen,
      setModalOpen,
      handleSubmit,
      setSelectedRequirement,
      setCurrentProductId,
      actionSubmitLoading,
      submitRetailerResponse,
      setToastOpen,
      selectedRequirement,
      confirmClose,
      setConfirmClose,
      initEdit,
      setInitEdit,
      hasChanged,
      setHasChanged,
      setAttestationActions,
      hasErrors,
    }),
    [
      handleAttestation,
      updateIngredientFunction,
      addIngredient,
      updatePercentage,
      resolveIngredientName,
      conditions,
      canAttest,
      conditionsLoading,
      conditionsError,
      toastData,
      handleChange,
      triggerValidation,
      handleConditionValidation,
      modalOpen,
      setModalOpen,
      handleSubmit,
      setSelectedRequirement,
      setCurrentProductId,
      actionSubmitLoading,
      submitRetailerResponse,
      setToastOpen,
      selectedRequirement,
      confirmClose,
      setConfirmClose,
      initEdit,
      setInitEdit,
      hasChanged,
      setHasChanged,
      setAttestationActions,
      hasErrors,
    ]
  );
  const isSuccess = toastData?.kind === ToastKind.Success;

  return (
    <RequirementActions.Provider value={context}>
      {children}
      <ContextMenu open={toastOpen}>
        <div className={styles.toast}>
          <span
            className={cn([
              styles['icon-container'],
              isSuccess
                ? styles['icon-container-green']
                : styles['icon-container-red'],
            ])}
          >
            <span
              className={cn([
                styles['icon-inner'],
                isSuccess
                  ? styles['icon-inner-green']
                  : styles['icon-inner-red'],
              ])}
            >
              <PhosphorIcon
                iconName={isSuccess ? 'CheckCircle' : 'WarningCircle'}
                weight={PhosphorIconWeight.Bold}
                size={24}
              />
            </span>
          </span>
          <div>
            <Text kind={TextKind.TextSMSemibold} element={TextElement.H3}>
              {toastData?.title}
            </Text>
            <Text kind={TextKind.TextSM} element={TextElement.P}>
              {toastData?.message}
            </Text>
          </div>
          <CloseButton setToastOpen={setToastOpen} />
        </div>
      </ContextMenu>
    </RequirementActions.Provider>
  );
};

const CloseButton = ({ setToastOpen }) => {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current?.focus();
    }
  }, []);
  return (
    <Button
      tabIndex={0}
      kind={ButtonKind.Close}
      usePhosphorIcon
      iconWeight={PhosphorIconWeight.Bold}
      iconName="X"
      iconSize={24}
      onlyIcon
      aria-label="Close Toast"
      data-cy="toast-close-button"
      id="toast-close-button"
      onClick={() => setToastOpen(false)}
      onKeyDown={(event) => {
        handleKeyboardEvent(
          event,
          [KeyboardCode.Enter, KeyboardCode.Space],
          () => setToastOpen(false)
        );
      }}
      refProp={buttonRef}
    ></Button>
  );
};

// Custom hook to use RequirementActions context
export const useRequirementActions = () => {
  const ctx = useContext(RequirementActions);
  if (!ctx) {
    throw new Error('Must be used with a RequirementActionsProvider');
  }
  return ctx;
};
