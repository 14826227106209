import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BrandStatusDisplayData,
  NavDataProps,
  NavDataReturnData,
} from 'design-system/components';

import {
  PATHS,
  FDA_REGISTRATION_PATH,
  RETAILER_PATH,
  BRAND_PATH,
  RETAILER_BRAND_PATH,
  ROLES,
} from 'constants/index';
import useUserFlow from 'hooks/useUserFlow';
import fetcher from 'services/api/fetcher';
import useAsyncCall from 'services/api/useAsyncCall';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';
import { useUserInvitation } from 'context/UserInvitationContext';
import { useApp } from 'context/AppContext';
import { isSelectedRetailerInLegacyPortal } from 'views/Brands/PortalSelection/util';
import useRetailer from './useRetailer';

const useNavData = ({ setHideMobileNav }: NavDataProps): NavDataReturnData => {
  const history = useHistory();
  const {
    auth0,
    user,
    retailerBrand: lastSelectedRetailerBrand,
    subscriptionTiers,
    selectedRetailerId,
    selectedRetailerBrandId,
    setSelectedRetailerBrandId,
    isInMoCRAPortal,
    refetchUser,
  } = useApp();

  const isBrand = user?.hasRole(ROLES.brand);
  const isRetailer = user?.hasRole(ROLES.retailer);
  const isSupplier = user?.hasRole(ROLES.supplier);
  const isFormulator = user?.hasRole(ROLES.formulator);
  const isContractManufacturer = user?.hasRole(ROLES.contractManufacturer);
  const hasRetailerBrandPortalV1Flag = user?.hasFF('retailer_brand_experience');
  const hasOnboardingFlag = user?.hasFF('retailer_brand_onboarding');
  const hasSephoraRetailerFlow = user?.hasFF('sephora_retailer_flow');
  const hasProductRequests = user?.hasFF('product_requests_tracker');
  const hasReportsPage = user?.hasFF('sephora_reports_page') && isRetailer;
  const hasEligibilityFlag = user?.hasFF('retailer_brand_eligibility');
  const disableUltaV1 = user?.hasFF('tmp_disable_ulta_v1');

  const isSelectedRetailerInLegacy = isSelectedRetailerInLegacyPortal(
    selectedRetailerId,
    user
  ); // cleanup with Ulta migration
  const isSelectedRetailerInV2 = !isSelectedRetailerInLegacy; // cleanup with Ulta migration
  const { unlocked, can_submit_policy } = lastSelectedRetailerBrand || {};
  const hasUnpaidSubscriptions = !unlocked;
  const flow = useUserFlow();

  const {
    user: { permissions },
  } = flow;
  const legacySephora = flow.legacy_sephora_flow;
  const orgId = user?.organization.in_orgable_id;
  const canScreen = permissions?.canScreenIngredients;
  const canPurchase = permissions?.canPurchase && user?.po_count > 0;

  const hasV1UltaPortal =
    !disableUltaV1 && !hasOnboardingFlag && hasRetailerBrandPortalV1Flag; // cleanup with Ulta migration
  const isUltaPortal =
    !disableUltaV1 &&
    (hasV1UltaPortal || isSelectedRetailerInLegacy) &&
    isBrand; // cleanup with Ulta migration

  const retailerId = isRetailer ? orgId : isUltaPortal ? 37 : null; // cleanup with Ulta migration

  const { retailer } = useRetailer(retailerId);

  const logout = () => {
    auth0?.client.logout({ returnTo: window.location.origin });
  };

  const retailerBrand = useMemo(() => {
    return isBrand && selectedRetailerBrandId
      ? { id: selectedRetailerBrandId, brandId: orgId }
      : null;
  }, [isBrand, orgId, selectedRetailerBrandId]);

  const base = useMemo(() => {
    switch (true) {
      case isInMoCRAPortal:
        return FDA_REGISTRATION_PATH;
      case retailerBrand && !isUltaPortal:
        return RETAILER_BRAND_PATH;
      // base for legacy retailer brand flow.
      case retailerBrand && isUltaPortal:
        return `${BRAND_PATH}/${retailerBrand.brandId}`;
      case !retailerBrand && isUltaPortal:
        return `${BRAND_PATH}/${selectedRetailerBrandId}`;
      case retailer:
        return RETAILER_PATH;
      default:
        return RETAILER_PATH;
    }
  }, [
    isUltaPortal,
    retailer,
    retailerBrand,
    isInMoCRAPortal,
    selectedRetailerBrandId,
  ]);
  const generateHref = (path: string) => `${base}/${path}`;
  const isPathActive = (path: string[] | string) => {
    if (Array.isArray(path)) {
      return path.some((path) => window.location.pathname.includes(path));
    }
    return window.location.pathname.includes(path);
  };

  // logic to support retailers, retailer brand legacy portal and retailer brand new portal
  const homeUrls = useMemo(() => {
    if (isSupplier) {
      return;
    }
    return {
      ...((!isUltaPortal || retailer) && {
        dashboard: generateHref('dashboard'),
      }),
      ...(isUltaPortal && {
        dashboard: generateHref('retailer-certifications'),
      }),
      ...(hasReportsPage && {
        reports: generateHref('reports'),
      }),
      ...(retailerBrand && !isUltaPortal && { base }),
      ...(retailerBrand &&
        !isUltaPortal && {
          pillars: generateHref('requirement_groups'),
        }),
      ...(!isRetailer && {
        skus: generateHref(!isUltaPortal ? 'skus' : 'products'),
      }),
      ...(hasEligibilityFlag &&
        retailerBrand &&
        !isUltaPortal && {
          eligibility: generateHref('eligibility'),
        }),
      ...(isUltaPortal && {
        status: generateHref('status'),
      }),
      documents: generateHref('documents'),
      ...(retailerBrand &&
        !isUltaPortal && {
          submissions: generateHref('submissions'),
        }),
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUltaPortal, retailer, retailerBrand, hasReportsPage, base]);

  const statusData = hasUnpaidSubscriptions
    ? BrandStatusDisplayData.Unpaid
    : BrandStatusDisplayData.Paid;

  const { analyticsWrap } = useAnalyticsEvent();
  const { openModal: openInvitationModal } = useUserInvitation();
  const hasV2OnboardingData: Boolean =
    isSelectedRetailerInV2 &&
    lastSelectedRetailerBrand?.retailer &&
    selectedRetailerBrandId &&
    isBrand &&
    !isRetailer;

  const clickWrapper = ({
    label = 'navigation',
    action = '',
    callback = () => {},
  }) => {
    setHideMobileNav(true);
    return analyticsWrap(
      {
        label,
        category: 'left_nav',
        action,
      },
      callback
    )();
  };

  const marketplaceData = {
    show: !isSupplier && !isContractManufacturer,
    text: 'Marketplace',
    path: PATHS.product_marketplace,
    search: 'category_key=ingredient',
    icon: 'ShoppingCartSimple',
    onClick: () => clickWrapper({ action: 'click_menu_marketplace' }),
  };

  const sephoraData = {
    show: legacySephora,
    text: 'Sephora Planet Delta',
    path: PATHS.sephoraBrandDashboard,
    onClick: () =>
      clickWrapper({ action: 'click_menu_sephora_brand_dashboard' }),
  };

  // Wrapper function to adapt async functions to the expected signature
  function asyncWrapper(asyncFn: (...args: any[]) => Promise<any>) {
    return (...args: any[]) => {
      asyncFn(...args);
      return null; // Ensure the function returns null synchronously
    };
  }

  const onLegacyUltaPortalClick: any = useAsyncCall(
    asyncWrapper(async (): Promise<null> => {
      clickWrapper({ action: 'click_menu_ulta_brand_portal' });
      const rbID = (retailer as any)?.retailer_consumer_brand_id;
      fetcher(`/api/v4/retailer_consumer_brands/${rbID}/set_last_selected`, {
        method: 'post',
      });
      refetchUser({ soft: true });
      setSelectedRetailerBrandId(rbID);
      return null;
    })
  );

  const onLegacyUltaMocraClick: any = useAsyncCall(
    asyncWrapper(async (): Promise<null> => {
      clickWrapper({ action: 'click_enter_portal_button_mocra' });
      await fetcher(`/api/v4/retailer_consumer_brands/clear_last_selected`, {
        method: 'post',
      });
      await refetchUser({ soft: true });
      setSelectedRetailerBrandId(undefined);
      return history.push(PATHS.fdaRegistrationWelcome);
    })
  );

  const legacyUltaBrandData = {
    show:
      isBrand &&
      hasV1UltaPortal &&
      permissions?.canAccessRetailerBrandExperience,
    ulta: {
      text: 'Ulta Brand Portal',
      path: PATHS.retailerBrandPortal,
      onClick: () => onLegacyUltaPortalClick.call(),
    },
    mocra: {
      text: 'MoCRA',
      path: '/',
      onClick: (e: any) => {
        e?.preventDefault();
        onLegacyUltaMocraClick.call();
      },
    },
  };

  const workbenchData = {
    show: isBrand || isFormulator || isContractManufacturer,
    active: isPathActive([
      isFormulator ? PATHS.formulatorDashboard : PATHS.projectDashboard,
      PATHS.ingredient_search,
    ]),
    text: 'Workbench',
    icon: 'Flask',
    formulation: {
      text:
        isBrand || isContractManufacturer
          ? 'Formulation Verification'
          : 'Projects',
      path: isFormulator ? PATHS.formulatorDashboard : PATHS.projectDashboard,
      onClick: () => clickWrapper({ action: 'click_menu_verify_formulas' }),
    },
    ingredientScreener: {
      show: canScreen,
      text: 'Ingredient Screener',
      path: PATHS.ingredient_search,
      onClick: () => clickWrapper({ action: 'click_menu_ingredient_screener' }),
    },
  };

  const onboardingData = {
    selectedRetailer: lastSelectedRetailerBrand?.retailer,
    invoiceLink: lastSelectedRetailerBrand?.stripe_invoice_id,
  };

  const retailerBrandsData = {
    show: isRetailer,
    text: 'Brands',
    icon: 'Flask',
    active: isPathActive(PATHS.retailerBrandsView),
    metrics: {
      text: 'Metrics',
      path: PATHS.retailerBrandsMetrics,
      onClick: () => clickWrapper({ action: 'click_menu_brand_metrics' }),
    },
    submissions: {
      text: 'Submissions',
      path: PATHS.retailerBrandsSubmissions,
      active: isPathActive(PATHS.retailerBrandsSubmissions),
      onClick: () => clickWrapper({ action: 'click_menu_brand_submissions' }),
    },
  };

  const retailerSkusData = {
    show: isRetailer,
    text: 'SKUs',
    icon: 'Flask',
    active: isPathActive(PATHS.retailerSkuMetrics),
    metrics: {
      text: 'Metrics',
      path: PATHS.retailerSkuMetrics,
      onClick: () => clickWrapper({ action: 'click_menu_skus_metrics' }),
    },
  };

  const retailerAdditionalReports = {
    show: isRetailer && (retailer as any)?.additional_dashboards?.length > 0,
    text: 'Additional Reports',
    icon: 'Flask',
    active: isPathActive(PATHS.retailerAdditionalReports),
    additionalReports: (retailer as any)?.additional_dashboards?.map(
      (report: any) => {
        return { text: report.title, id: report.id };
      }
    ),
  };

  const moCRAPortalData = {
    show: isInMoCRAPortal,
    // show: false,
    base: {
      text: 'MoCRA',
      path: PATHS.base,
      // TODO: Implement Anayltics Tracking for MoCRAPortal Menu Clicks
      // https://linear.app/novi/issue/ENG-4944/
      onClick: () => setHideMobileNav(true),
    },
    dashboard: {
      text: 'Dashboard',
      onClick: () => {
        setHideMobileNav(true);
      },
    },
    skus: {
      text: 'SKUs',
      onClick: () => setHideMobileNav(true),
    },
    submissions: {
      text: 'Submissions',
      onClick: () => setHideMobileNav(true),
    },
  };

  const supplierData = {
    show: isSupplier,
    listings: {
      text: 'Listings',
      icon: 'Tag',
      path: PATHS.supplierListings,
      onClick: () => clickWrapper({ action: 'click_supplier_listings' }),
    },
  };

  const formulatorData = {
    show: isFormulator,
    purchaseOrders: {
      show: canPurchase,
      text: 'Purchase Orders',
      icon: 'Receipt',
      path: PATHS.purchaseOrders,
      onClick: () => clickWrapper({ action: 'click_menu_purchase_order' }),
    },
    productRequests: {
      show: hasProductRequests && canPurchase,
      text: 'Product Requests',
      icon: 'Tray',
      path: PATHS.ingredientRequests,
      onClick: () => clickWrapper({ action: 'click_menu_product_requests' }),
    },
  };

  const contractManufacturerData = {
    show: isContractManufacturer,
    home: {
      text: 'Home',
      path: PATHS.base,
      icon: 'House',
      onClick: () => clickWrapper({ action: 'click_menu_home' }),
    },
    sephoraBrandResults: {
      show: hasSephoraRetailerFlow,
      text: 'Sephora Brand Results',
      onClick: () => clickWrapper({ action: 'click_menu_sephora_brands' }),
    },
  };

  const inviteModalData = {
    show: isFormulator || isContractManufacturer,
    text: 'Invite your Team',
    onClick: () =>
      clickWrapper({
        label: 'user_invitation_modal',
        action: 'click_user_invitation_cta',
        callback: openInvitationModal,
      }),
  };

  const selectedRetailer = onboardingData?.selectedRetailer || retailer;
  const supportEmail =
    selectedRetailer?.support_email || 'support@noviconnect.com';

  const navData = {
    ...(hasV2OnboardingData ? onboardingData : {}),
    moCRAPortalData,
    workbenchData,
    formulatorData,
    sephoraData,
    legacyUltaBrandData,
    retailerBrandsData,
    retailerSkusData,
    supplierData,
    contractManufacturerData,
    marketplaceData,
    inviteModalData,
    selectedRetailerBrandId,
    homeUrls,
    supportEmail,
    statusData,
    logout,
    isPathActive,
    generateHref,
    base,
    selectedRetailer,
    isUltaPortal,
    isInMoCRAPortal,
    displayRequestToPay: !can_submit_policy,
    hasUnpaidSubscriptions,
    username: user?.nickname,
    email: user?.email,
    isBrand: isBrand,
    retailerAdditionalReports,
    subscriptionTiers,
  };

  return navData;
};

export default useNavData;
