import { Text, TextElement, TextKind } from 'design-system/components';
import { useApp } from 'context/AppContext';
import { useRetailerContext } from '../../context';
import { isRetailerWithQuicksight } from 'utils/retailer';
import QuickSightDashboard from 'components/QuicksightDashboard';
import MetabaseDashboard from 'components/MetabaseDashboard';
import styles from './retailer-dashboard.module.scss';

function RetailerDashboard() {
  const { retailerID, retailerName } = useRetailerContext();
  const { user } = useApp();

  const dashboardType = 'overview-metrics';

  const hasAccess = user.hasFF('metabase_dashboard_view');

  // once a user gets the FF enabled, they should no longer see quicksight even without a change to the code (remove id from list of retailers with quicksight access)
  // chore: once a user does have FF enabled, eventually their id should be removed from the quicksight list
  const showQuicksightDashboard =
    retailerID && isRetailerWithQuicksight(retailerID) && !hasAccess;

  return (
    <>
      <Text
        element={TextElement.H1}
        kind={TextKind.TextMD}
        className={styles['text']}
      >
        Hi {retailerName}! Here is your dashboard.
      </Text>
      {showQuicksightDashboard ? (
        <QuickSightDashboard
          dashboardType={dashboardType}
          retailerID={retailerID}
        />
      ) : (
        <MetabaseDashboard
          dashboardType={dashboardType}
          retailerID={retailerID}
        />
      )}
    </>
  );
}

export default RetailerDashboard;
