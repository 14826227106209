import { useMemo } from 'react';
import propTypes from 'prop-types';
import {
  Avatar,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'design-system/components';
import { PolicyStatusPill, BooleanStatusPills } from './status-pills';
import Tooltip from './tooltip';
import { determineInitials } from 'design-system/utils';
import styles from '../status.module.scss';
import { PLACEHOLDER, ScreeningStatus } from 'design-system/data';

const tableHeaders = [
  {
    text: 'Pillar',
    tooltipMessage: null,
  },
  {
    text: 'Pillar Agreement',
    tooltipMessage:
      'Indicates whether pillar agreement has been fully executed',
  },
  {
    text: 'Status',
    tooltipMessage:
      'Indicates whether pillar requirements have been met based on data and attestations submitted',
  },
  {
    text: 'All SKUs Certified',
    tooltipMessage: "Indicates if 100% of brand's active SKUs are certified",
  },
];

const sortPolicies = (a, b) => a.ordering - b.ordering;

function PillarTable({ data }) {
  const buildContainedPolicies = useMemo(() => {
    let containedPolicies = [];
    if (!data) return containedPolicies;
    Object.entries(data).forEach((entry) => {
      if (entry[0] === 'formulationPolicies') return;
      containedPolicies.push(...(Array.isArray(entry?.[1]) ? entry[1] : []));
    });
    return containedPolicies;
  }, [data]);

  const containedPolicies = buildContainedPolicies.sort(sortPolicies);
  const formulationPolicies = data?.formulationPolicies?.sort(sortPolicies);

  return (
    <div className={styles.table}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(({ text, tooltipMessage }, index) => (
              <TableHeadCell key={`${text.toLowerCase()}-${index}`}>
                <div className={styles['table-head-cell']}>
                  {text}
                  {tooltipMessage && (
                    <Tooltip tooltipMessage={tooltipMessage} />
                  )}
                </div>
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {formulationPolicies?.map((fpolicy, index) => {
              const isComplete =
                fpolicy.retailerPolicyRequirementScreeningResult ===
                ScreeningStatus.Pass;
              const formulationPolicyStatus =
                fpolicy.status === ScreeningStatus.Pass
                  ? ScreeningStatus.Pass
                  : ScreeningStatus.Fail;
              return (
                <TableRow key={`${fpolicy.id}-${index}`}>
                  <TableBodyCell>
                    <div className={styles['avatar-cell']}>
                      <Avatar
                        initials={determineInitials(fpolicy.name)}
                        src={fpolicy.imagePath}
                      />
                      {fpolicy.name}
                    </div>
                  </TableBodyCell>
                  <TableBodyCell>
                    <BooleanStatusPills
                      type="enrollment"
                      status={fpolicy.isEnrolled}
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <PolicyStatusPill status={formulationPolicyStatus} />
                  </TableBodyCell>
                  <TableBodyCell>
                    <BooleanStatusPills type="isComplete" status={isComplete} />
                  </TableBodyCell>
                </TableRow>
              );
            })}
            {containedPolicies.map((containedPolicy, index) => {
              let policyStatus = containedPolicy?.status;
              if (containedPolicy?.type === 'formulation_policy') {
                policyStatus =
                  policyStatus === ScreeningStatus.Pass
                    ? ScreeningStatus.Pass
                    : ScreeningStatus.Fail;
              }
              return (
                <TableRow key={`${containedPolicy.id}-${index}`}>
                  <TableBodyCell>
                    <div className={styles['avatar-cell']}>
                      <Avatar
                        initials={determineInitials(containedPolicy.name)}
                        src={containedPolicy.imagePath}
                      />
                      {containedPolicy.name}
                    </div>
                  </TableBodyCell>
                  <TableBodyCell>
                    {/* TODO: Enrollment status is dependent on ENG-3509 */}
                    <BooleanStatusPills
                      type="enrollment"
                      status={containedPolicy.isEnrolled}
                    />
                  </TableBodyCell>
                  <TableBodyCell>
                    <PolicyStatusPill status={policyStatus} />
                  </TableBodyCell>
                  <TableBodyCell>
                    {containedPolicy?.type !== 'formulation_policy' &&
                      PLACEHOLDER}
                    {containedPolicy?.type === 'formulation_policy' && (
                      <BooleanStatusPills
                        type="isComplete"
                        status={
                          containedPolicy?.retailerPolicyRequirementScreeningResult ===
                          ScreeningStatus.Pass
                        }
                      />
                    )}
                  </TableBodyCell>
                </TableRow>
              );
            })}
          </>
        </TableBody>
      </Table>
    </div>
  );
}

PillarTable.propTypes = {
  data: propTypes.shape({}),
};

export default PillarTable;
