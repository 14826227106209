import { CSSProperties, useMemo } from 'react';
import {
  NoContent,
  NoDataVariants,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableFoot,
  TableBodyCell,
  PaginationV2,
} from 'design-system/components';
import { useStatefulQueryParam } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

import { TableDataResponse } from '../data/types';
import SKULevelRow from './SKULevelRow';
import styles from '../sku-level-status.module.scss';

interface TableData {
  data?: TableDataResponse;
  loading?: boolean;
  error?: any;
}

interface SKULeveleStatusTableProps {
  data: TableData;
  uniquePillarResultNames?: string[];
  isRetailer?: boolean;
}

const SKULevelStatusTable = ({
  data: tableData,
  uniquePillarResultNames,
  isRetailer,
}: SKULeveleStatusTableProps) => {
  const { data, loading, error } = tableData;
  const [page, setPage] = useStatefulQueryParam(UrlParam.Page);
  const currentPath = window.location.pathname;

  const columns: { label: string; value: string }[] = useMemo(
    () => [
      {
        label: 'SKU ID',
        value: 'sku_id',
      },
      {
        label: 'SKU Name',
        value: 'name',
      },
      {
        label: `${isRetailer ? '' : 'Program '}Status`,
        value: 'program_status',
      },
      ...(uniquePillarResultNames?.length
        ? uniquePillarResultNames.map((pillarName) => {
            return {
              label: pillarName,
              value: pillarName,
            };
          })
        : []),
      {
        label: '',
        value: 'cta',
      },
    ],
    [uniquePillarResultNames, isRetailer]
  );

  const colSpan = columns.length;
  const tableStyle = {
    '--col-span': colSpan.toString(),
  } as CSSProperties;

  if (loading && !error) {
    return <NoContent loading>(This may take up to a minute)</NoContent>;
  }

  if (error) {
    return (
      <NoContent variant={NoDataVariants.Default}>
        An error occurred while fetching the data. Please try again later or
        reach out to support.
      </NoContent>
    );
  }

  const noContent = !data?.results.length && !loading && !error;

  if (noContent) {
    return (
      <NoContent variant={NoDataVariants.Default}>
        No results found at this time. If you believe this is an error please
        reach out to support.
      </NoContent>
    );
  }

  return (
    <div className={styles.table} style={tableStyle}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, i) => (
              <TableHeadCell key={`${column.value}-${i}`}>
                {column.label}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.results.map((result, i) => (
            <SKULevelRow
              key={`${result.skuId}-${i}`}
              data={result}
              pillarNames={uniquePillarResultNames}
              url={`${currentPath}/${result?.productId}/requirements`}
            />
          ))}
        </TableBody>
        <TableFoot bgWhite>
          <TableRow>
            <TableBodyCell colSpan={colSpan}>
              <PaginationV2
                onNextPage={() => page && setPage && setPage(+page + 1)}
                onPreviousPage={() => page && setPage && setPage(+page - 1)}
                onPageSelect={setPage}
                currentPage={data?.page}
                totalItemCount={data?.count}
                itemsPerPage={data?.items}
              />
            </TableBodyCell>
          </TableRow>
        </TableFoot>
      </Table>
    </div>
  );
};

export default SKULevelStatusTable;
