import { useCallback } from 'react';
import {
  Link,
  LinkKind,
  LinkSize,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  TableBodyCell,
  TableRow,
  Tooltip,
  OpenAxis,
  LinkVariant,
} from 'design-system/components';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import { PillarResult, Result } from '../data/types';
import styles from '../sku-level-status.module.scss';

const SKULevelRow = ({
  data,
  pillarNames,
  url,
}: {
  data: Result;
  pillarNames?: string[];
  url: string;
}) => {
  const maximumNameCharacters = 20;
  const maximumIdCharacters = 10;

  const truncatedText = useCallback((dataText: string, charLength: number) => {
    const text = dataText;
    if (typeof text !== 'string') return text;
    return text?.length > charLength ? `${text.slice(0, charLength)}...` : text;
  }, []);

  // EG: { 'Vegan Pillar': { pillarData }, 'Cruelty-Free Pillar': { pillarData } }
  const pillarMap: { [key: string]: PillarResult } = data.pillarResults.reduce(
    (acc: { [key: string]: PillarResult }, pillar: PillarResult) => {
      acc[pillar.name] = pillar;
      return acc;
    },
    {}
  );

  // Ensure pillar results show in correct column order
  const alignedPillarResults = pillarNames?.map(
    (pillarName) => pillarMap[pillarName] || null
  );

  return (
    <TableRow
    // onClick={tableRowOnClick}
    >
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
              {truncatedText(data.skuId?.toString(), maximumIdCharacters)}
            </Text>
          }
          disabled={data.skuId?.toString().length <= maximumIdCharacters}
        >
          {data.skuId}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
              {truncatedText(data.name, maximumNameCharacters)}
            </Text>
          }
          disabled={data.name?.length <= maximumNameCharacters}
        >
          {data.name}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={data.screeningStatus} />
      </TableBodyCell>
      {alignedPillarResults?.length &&
        alignedPillarResults.map((pillar: PillarResult, i) => {
          return pillar?.screeningStatus ? (
            <TableBodyCell key={`${pillar.requirementGroupId}-${i}`}>
              <ScreeningStatusPill withDot status={pillar.screeningStatus} />
            </TableBodyCell>
          ) : (
            // If no pillar result exists for some reason, show placeholder
            <TableBodyCell key={i}>-</TableBodyCell>
          );
        })}
      <TableBodyCell>
        <div className={styles['cta-container']}>
          <Link
            kind={LinkKind.Internal}
            variant={LinkVariant.Alternate}
            size={LinkSize.Small}
            to={url}
          >
            <div className={styles.cta}>
              Review
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={18} />
              </span>
            </div>
          </Link>
        </div>
      </TableBodyCell>
    </TableRow>
  );
};
export default SKULevelRow;
