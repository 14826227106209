import { ReactNode } from 'react';
import { TextKind, LinkKind } from 'design-system/components';
import { PATHS } from 'constants/index';
import checklistSvg from 'assets/illustrations/checklist.svg';
import rocketSvg from 'assets/illustrations/rocket.svg';
import chatSvg from 'assets/illustrations/chat.svg';
import planetSvg from 'assets/illustrations/planet.svg';
import { BrandPolicySubmissionStatus } from 'design-system/data';

export interface CompletePolicyStateType {
  svg: string;
  alt: string;
  links?: Link[];
  description: string;
  title: string;
  titleKind: string;
  subtitle?: string;
  showSubmitButton?: boolean;
  phosphorIconName?: string;
}

export interface SKUPolicyStateType {
  title: string;
  subtitle: string;
  link?: Link;
  phosphorIconName: string;
  submitButton?: ReactNode;
}

export interface Link {
  to: string;
  text: string;
  linkKind?: string;
}

export const PolicySubmissionFlow = {
  NeedsInformation: (name: string): CompletePolicyStateType => {
    return {
      svg: checklistSvg,
      alt: 'checklist',
      links: [
        {
          to: PATHS.retailerBrandProducts,
          text: 'Finish uploading your SKUs',
        },
        {
          to: PATHS.retailerBrandProducts,
          text: 'View your SKUs',
        },
        {
          to: PATHS.retailerBrandRequirementGroups,
          text: 'Complete the attestation process',
        },
      ],
      description: '',
      title: `Next steps${name ? ` for ${name} ` : ''}`,
      titleKind: TextKind.DisplayXSMedium,
    };
  },
  Pass: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: rocketSvg,
      alt: 'rocket',
      description: `Would you like to submit your verification to ${retailerName} for review? If any requirements are still in a “pending” state, ${retailerName} will review them to ensure that they pass.`,
      title: `You completed all the required verifications for ${
        name ? `${name} at ` : ''
      }${retailerName}!`,
      titleKind: TextKind.Display2XSMedium,
      showSubmitButton: true,
    };
  },
  Fail: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: checklistSvg,
      alt: 'checklist',
      subtitle: 'Next steps',
      links: [
        {
          to: PATHS.retailerBrandRequirementGroups,
          text: 'Return to the attestation process',
        },
      ],
      description: `You didn’t pass all of the ${name} requirements. You will need to address this before you can submit your responses to ${retailerName}.`,
      title: `Something’s not quite right…`,
      titleKind: TextKind.DisplaySMMedium,
    };
  },
  Pending: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: chatSvg,
      alt: 'chat',
      description: `You will receive a notification when ${retailerName} has finished reviewing your responses.`,
      title: `Your attestations for ${name} are currently in review.`,
      titleKind: TextKind.Display2XSMedium,
    };
  },
  Approved: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: planetSvg,
      alt: 'planet',
      subtitle: 'Next steps',
      links: [
        {
          to: PATHS.retailerBrandSubmissions,
          text: `Read ${retailerName}’s comments`,
        },
        {
          to: PATHS.retailerBrandProducts,
          text: 'View your SKUs',
        },
      ],
      description: `You have completed all the requirements for ${name} and your submission to ${retailerName} has been approved.`,
      title: `Congratulations! You’re approved for ${name}.`,
      titleKind: TextKind.DisplayXSMedium,
    };
  },
  Rejected: (name: string, retailerName: string): CompletePolicyStateType => {
    return {
      svg: checklistSvg,
      alt: 'checklist',
      subtitle: 'Next steps',
      links: [
        {
          to: PATHS.retailerBrandSubmissions,
          text: `View ${retailerName}’s comments`,
        },
      ],
      description: `You didn’t pass all of the ${name} requirements. Here’s what to do next.`,
      title: `Something’s not right…`,
      titleKind: TextKind.DisplaySMMedium,
    };
  },
};

export const OneOrMoreSKUsSubmissionFlow = {
  NeedsInformation: (
    name: string,
    retailerName: string
  ): SKUPolicyStateType[] => [
    {
      title: 'Work on your attestations',
      subtitle: `None of your SKUs qualify for ${name} yet. Learn more about the program requirements and complete the necessary attestations in order to submit to ${retailerName}.`,
      link: {
        to: PATHS.retailerBrandRequirementGroups,
        text: 'Attest',
      },
      phosphorIconName: 'ListChecks',
    },
  ],
  Pass: (
    name: string,
    retailerName: string,
    submitButton: ReactNode
  ): SKUPolicyStateType[] => [
    {
      title: 'Continue your attestations',
      subtitle: `Some of your SKUs qualify for ${name}. Submit your work to ${retailerName} in order to request a review. Once you submit, you will not be able to edit your answers or complete more attestations until the retailer has reviewed your submission.`,
      link: {
        to: PATHS.retailerBrandRequirementGroups,
        text: 'Attest',
      },
      phosphorIconName: 'ListChecks',
    },
    {
      title: 'Submit your attestations',
      subtitle: `Some of your SKUs qualify for ${name}. Submit your work to ${retailerName} in order to request a review. Once you submit, you will not be able to edit your answers or complete more attestations until the retailer has reviewed your submission.`,
      phosphorIconName: 'PaperPlaneTilt',
      submitButton,
    },
  ],
  Fail: {},
  Pending: (
    name: string,
    retailerName: string,
    retailerSupportEmail: string
  ): SKUPolicyStateType[] => [
    {
      title: 'Your submission is pending',
      subtitle: `Your ${name} attestations have been submitted to ${retailerName}. You will not be able to edit your answers or complete more attestations until your submission has been reviewed. If you need to make a change, please contact support.`,
      link: {
        to: `mailto:${retailerSupportEmail}`,
        text: 'Get in touch',
        linkKind: LinkKind.External,
      },
      phosphorIconName: 'SpinnerGap',
    },
  ],
  Approved: (name: string, retailerName: string): SKUPolicyStateType[] => [
    {
      title: 'Your submission has been accepted',
      subtitle: `Your completed ${name} attestations have been accepted by ${retailerName}! Any pending requirements have been reviewed; review your submissions page for any comments.`,
      link: {
        to: PATHS.retailerBrandSubmissions,
        text: 'Review',
      },
      phosphorIconName: 'SealCheck',
    },
  ],
  Rejected: (name: string, retailerName: string): SKUPolicyStateType[] => [
    {
      title: 'Your submission was not accepted',
      subtitle: `Your completed ${name} attestations have been reviewed by ${retailerName}, but they found some issues; review your submissions page for more information.`,
      link: {
        to: PATHS.retailerBrandSubmissions,
        text: 'Review',
      },
      phosphorIconName: 'Warning',
    },
  ],
};

export const hydratePolicySubmissionFlow = (
  submissionStatus: string,
  name: string,
  retailerName: string
) => {
  switch (submissionStatus) {
    case BrandPolicySubmissionStatus.NeedsInformation:
      return PolicySubmissionFlow.NeedsInformation(name);
    case BrandPolicySubmissionStatus.Pass:
      return PolicySubmissionFlow.Pass(name, retailerName);
    case BrandPolicySubmissionStatus.Fail:
      return PolicySubmissionFlow.Fail(name, retailerName);
    case BrandPolicySubmissionStatus.Pending:
    case BrandPolicySubmissionStatus.Ready:
      return PolicySubmissionFlow.Pending(name, retailerName);
    case BrandPolicySubmissionStatus.Approved:
      return PolicySubmissionFlow.Approved(name, retailerName);
    case BrandPolicySubmissionStatus.Rejected:
      return PolicySubmissionFlow.Rejected(name, retailerName);
    default:
      return null;
  }
};

export const hydrateSKUsFlow = (
  submissionStatus: string,
  name: string,
  retailerName: string,
  retailerSupportEmail: string,
  submitButton: ReactNode,
  someSkusQualify: boolean
): SKUPolicyStateType[] => {
  switch (submissionStatus) {
    case BrandPolicySubmissionStatus.NeedsInformation:
      return someSkusQualify
        ? OneOrMoreSKUsSubmissionFlow.Pass(name, retailerName, submitButton)
        : OneOrMoreSKUsSubmissionFlow.NeedsInformation(name, retailerName);
    case BrandPolicySubmissionStatus.Pass:
      return OneOrMoreSKUsSubmissionFlow.Pass(name, retailerName, submitButton);
    // case BrandPolicySubmissionStatus.Fail:
    //   return OneOrMoreSKUsSubmissionFlow.Fail;
    case BrandPolicySubmissionStatus.Pending:
      return OneOrMoreSKUsSubmissionFlow.Pending(
        name,
        retailerName,
        retailerSupportEmail
      );
    case BrandPolicySubmissionStatus.Ready:
      return OneOrMoreSKUsSubmissionFlow.Pending(
        name,
        retailerName,
        retailerSupportEmail
      );
    case BrandPolicySubmissionStatus.Approved:
      return OneOrMoreSKUsSubmissionFlow.Approved(name, retailerName);
    case BrandPolicySubmissionStatus.Rejected:
      return OneOrMoreSKUsSubmissionFlow.Rejected(name, retailerName);
    default:
      return [];
  }
};
