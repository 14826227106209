import cn from 'classnames';
import {
  Link,
  LinkKind,
  LinkVariant,
  Progress,
  ProgressSize,
  Text,
  TextKind,
  TextElement,
  Tooltip,
  OpenAxis,
} from 'design-system/components';
import { BrandPolicySubmissionStatus, Color } from 'design-system/data';

import { Link as LinkType } from './constants';
import styles from '../retailerBrandDashboard.module.scss';

interface PolicySubmissionFlowCardProps {
  policyState: any;
  percentComplete: number;
  loading: boolean;
  canSubmit: boolean;
  submitButton: any;
  submissionStatus: any;
  supportEmail: string;
}

const PolicySubmissionFlowCard = ({
  policyState,
  percentComplete,
  loading,
  canSubmit,
  submitButton,
  submissionStatus,
  supportEmail,
}: PolicySubmissionFlowCardProps) => {
  return (
    <div className={cn([styles['next-steps'], styles.panel])}>
      <div className={styles['next-steps-left']}>
        <div className={styles['next-steps-left']}>
          <img
            src={policyState?.svg}
            alt={policyState?.alt}
            onError={() => <span className={styles.fallback} />}
          />
        </div>
        <div className={styles['next-steps-center']}>
          <Text
            className={styles['next-steps-header']}
            kind={policyState?.titleKind}
            element={TextElement.H2}
          >
            {policyState?.title}
          </Text>
          {policyState?.description && (
            <Text
              className={styles.description}
              element={TextElement.P}
              kind={TextKind.TextMD}
            >
              {policyState?.description}
            </Text>
          )}
          {policyState?.subtitle && (
            <Text element={TextElement.P} kind={TextKind.DisplayXSMedium}>
              {policyState?.subtitle}
            </Text>
          )}
          {policyState?.links?.length &&
            policyState?.links.map((link: LinkType, i: number) => (
              <Link
                key={`${link.to}-${i}`}
                kind={LinkKind.Internal}
                variant={LinkVariant.NewDefault}
                to={link.to}
              >
                {link.text}
              </Link>
            ))}
          {policyState?.showSubmitButton &&
            (canSubmit ? (
              submitButton
            ) : (
              <Tooltip openAxis={OpenAxis.Y} triggerElement={submitButton}>
                <Text kind={TextKind.TextSM} element={TextElement.P}>
                  You will be able to submit to retailer after paying your
                  invoice. If you need support, please email{' '}
                  <Link
                    kind={LinkKind.External}
                    href={`mailto:${supportEmail}`}
                    className={styles['tooltip-link']}
                  >
                    {supportEmail}
                  </Link>
                </Text>
              </Tooltip>
            ))}
        </div>
      </div>
      {submissionStatus !== BrandPolicySubmissionStatus.Rejected && (
        <div className={styles.progress}>
          <Progress
            percentage={percentComplete || 0}
            loading={loading}
            size={ProgressSize.Lg}
            color={
              percentComplete === 100
                ? Color.Green600.value
                : Color.Purple600.value
            }
          />
        </div>
      )}
    </div>
  );
};

export default PolicySubmissionFlowCard;
